import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FbService } from '../services/fb.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SignupStepsCompleteGuard implements CanActivate {

  constructor(private fb: FbService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    let prom: Promise<boolean> = new Promise((res, rej) => {
      this.fb.user.pipe(take(1)).subscribe((userData) => {
        if(userData.step < 5) {
          this.fb.fireSwal('Error', 'You must complete the sign up process before using Yusra App!', 'error');
          this.router.navigateByUrl('/complete-signup');
          res(false);
        } else {
          res(true);
        }
      })
    });
    return prom;
  }
}
