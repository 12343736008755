import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { TutorialGuard } from './guards/tutorial.guard';
import { SignupStepsCompleteGuard } from './guards/signup-steps-complete.guard';
import { AngularFireAuthGuard, hasCustomClaim, redirectUnauthorizedTo, redirectLoggedInTo } from '@angular/fire/auth-guard';

const adminOnly = () => hasCustomClaim('admin');
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['home']);
//const belongsToAccount = (next) => hasCustomClaim(`account-${next.params.id}`);

const routes: Routes = [
  {
    path: '',
    canActivate: [AngularFireAuthGuard, TutorialGuard], //also managed redirect of logged in users to home
    data: { authGuardPipe: redirectLoggedInToHome },
    loadChildren: './login/login.module#LoginPageModule'
  },
  { path: 'tutorial', loadChildren: './tutorial/tutorial.module#TutorialPageModule' },
  { path: 'manual', loadChildren: './manual/manual.module#ManualPageModule' },
  { path: 'volunteer', loadChildren: './volunteer/volunteer.module#VolunteerPageModule' },
  { path: 'signup', loadChildren: './signup/signup.module#SignupPageModule', canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectLoggedInToHome } },
  { path: 'complete-signup', loadChildren: './signup/signup.module#SignupPageModule' },
  { path: 'home', loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule), canActivate: [AngularFireAuthGuard, SignupStepsCompleteGuard], data: { authGuardPipe: redirectUnauthorizedToLogin } },
  { path: '**', redirectTo: 'home' }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
