export interface Referral {
    firstName: string;
    relationship: string;
    lastName: string;
    uid: string;
    message: string;
}
  
export interface Services {
    advice: boolean;
    childCare: boolean;
    giveFood: boolean;
    teach: boolean;
    visitElderly: boolean;
    visitSick: boolean;
    other: boolean;
    confirmedCorona: boolean;
    isolateCorona: boolean;
    mutualAid: boolean;
}

export interface SettingsInterface {
    genderPref: string;
    radius: number;
    services: Services;
    awaitingDBS: boolean;
    DBSverified: boolean;
    disclosureNumber: string;
}

export interface PersonalDetailsInterface {
    title: string;
    dob: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    contact: string;
    email: string;
}

export interface PersonalDetailsOpenInterface {
    gender: string;
    city: string;
    postCode: string;
    country: string;
}

export interface PersonalDetailsFullName {
    firstName: string;
    lastName: string;
}

export interface QualificationsEducationInterface {
    employed: string;
    industry: string;
    opportunities: string;
    jobList: Array<any>;
    qualification: string;
    summary: string;
}

export interface QualificationsEducationPrivateInterface {
    profilePicture: string;
    cv: string;
}

export interface AdditionalDetailsInterface {
    interestedInVolunteering: string;
    referral: Referral;
    ethnicity: string;
    religion: string;
    maritalStatus: string;
    children: string;
}

export interface RewardsInterface {
    rewardsList: Array<any>;
}

export interface RedeemedIdStatus {
    id: string;
    status: string;
}

export interface RedeemedListInterface {
    redeemedList: Array<RedeemedIdStatus>;
}

export interface EventsInterface {
    eventsList: Array<any>;
}

export interface PostInfoInterface {
    anonymous: boolean;
    summary: string;
    dbsVerified: boolean;
    awaitingDbs: boolean;
    type: string;
    uid: string;
}

export interface PostInfoPrivateInterface {
    firstName: string;
    lastName: string;
    profilePicture: string;
}

export interface PostInfoFinalInterface {
    anonymous: boolean;
    summary: string;
    dbsVerified: boolean;
    awaitingDbs: boolean;
    type: string;
    uid: string;
    firstName: string;
    lastName: string;
    profilePicture: string;
}

export interface NotificationsInterface {
    date: string;
    icon: string;
    id: string;
    message: string;
    pid: string;
    time: number;
    title: string;
    uid: string;
    url: string;
}

export interface PermissionsInterface {
    val: boolean;
}
  
export interface UserInterface {
    active: boolean;
    creationTime: string;
    mailingList: boolean;
    uid: string;
    userType: string;
    notificationsBadge: number;
    emailVerified: boolean;
    userOrgId: string;
    step: number;
    anonymous: boolean;
    languages: Array<any>;
    currentPostId: string;
    lat: number;
    lon: number;
    country: string;
    gender: string;
    employed: string;
    industry: string;
    tokens: Array<any>;
}

export interface PersonalDetailsProfile {
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    dob: string;
    gender: string;
    contact: string;
}

export interface AddressDetailsProfile {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    postCode: string;
    country: string;
}

export interface QualificationEducationDetailsProfile {
    cv: string;
    employed: string;
    industry: string;
    opportunities: string;
    jobList: Array<any>;
    qualification: string;
}

export interface OtherDetailsProfile {
    anonymous: boolean;
    languages: Array<any>;
    emailVerified: boolean;
    mailingList: boolean;
    uid: string;
}

export interface TopDetailsProfile {
    profilePicture: string;
    firstName: string;
    lastName: string;
    summary: string;
    userType: string;
    dbsVerified: boolean;
    awaitingDbs: boolean;
    anonymous: boolean;
}

export interface CoinsInterface {
    total: number;
    customerId: string;
}

export interface TransactionsInterface {
    coinsPurchased: number;
    createdDate: string;
    id: string;
    refundActive: boolean;
    refunded: boolean;
    status: string; // success, error, refunded
    refundId: string;
    token: string;
    chargeId: string;
    totalCost: number;
    transactionFee: number;
    transactionType: string; // purchase, spend, transfer
    currency: string;
    time: number;
}

export interface RedeemFormInterface {
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    dob: string;
    gender: string;
    contact: string;
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    city: string;
    postCode: string;
    country: string;
}

export interface UserReview {
    message: string;
    rating: number;
    uid: string;
}

export class User {
    constructor(private uid, private email, private mailingList) {}

    public createNewUser() {
        var newUser: UserInterface = {
            active: true,
            uid: this.uid,
            creationTime: new Date().toString(),
            notificationsBadge: 0,
            userType: 'user',
            mailingList: this.mailingList,
            emailVerified: false,
            userOrgId: '',
            step: 1,
            anonymous: false,
            languages: [],
            currentPostId: '',
            lat: 0,
            lon: 0,
            country: 'United Kingdom',
            gender: '',
            employed: 'no',
            industry: '',
            tokens: []
        }
        return newUser;
    }
    public createEmptyUserPersonalDetails() {
        var newPersonalDetails: PersonalDetailsInterface = {
            title: '',
            dob: '',
            addressLine1: '',
            addressLine2: '',
            addressLine3: '',
            contact: '',
            email: this.email
        }
        return newPersonalDetails;
    }
    public createPostInfo() {
        var postInfo: PostInfoInterface = {
            anonymous: false,
            summary: '',
            dbsVerified: false,
            awaitingDbs: false,
            type: 'user',
            uid: this.uid
        }
        return postInfo;
    }
    public createPostInfoPrivate() {
        var postInfoPrivate: PostInfoPrivateInterface = {
            firstName: '',
            lastName: '',
            profilePicture: ''
        }
        return postInfoPrivate;
    }
    public createUserSettings() {
        var newSettings: SettingsInterface = {
            genderPref: 'any',
            radius: 5,
            services: {
                advice: true,
                childCare: true,
                giveFood: true,
                teach: true,
                visitElderly: true,
                visitSick: true,
                other: true,
                confirmedCorona: true,
                isolateCorona: true,
                mutualAid: true
            },
            awaitingDBS: false,
            DBSverified: false,
            disclosureNumber: ''
        }
        return newSettings;
    }
}
