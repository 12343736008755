/*THIS SERVICE MANAGES FIREBASE AUTHENTICATION AND FIRESTORE ONLY*/

import { Injectable, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection, DocumentReference, QueryDocumentSnapshot } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
//import { AngularFireStorage } from '@angular/fire/storage';
//import { AngularFireFunctions } from '@angular/fire/functions';
import { Observable, of, zip } from 'rxjs';
import { Location } from '@angular/common';
import { take, switchMap, map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { User, UserInterface, SettingsInterface, RewardsInterface, EventsInterface, PersonalDetailsInterface, QualificationsEducationInterface, CoinsInterface } from '../interfaces/user';
import { OfferInterface } from '../interfaces/offer';
import { PostInterface, ContactInfoInterface } from '../interfaces/post';
import { PostInfoInterface, PostInfoPrivateInterface, PostInfoFinalInterface } from '../interfaces/user';
//import { Plugins } from '@capacitor/core';
//const { Device } = Plugins;
import * as firebase from 'firebase/app';
import { NavController } from '@ionic/angular';
import { GetAddressService } from './get-address.service';
import { ToastController } from "@ionic/angular";
// import { CometChat } from "@cometchat-pro/chat";
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class FbService implements OnInit {

  deviceInfo;
  oldIphone = false;

  userAdmin: boolean | undefined = undefined
  userOrg: boolean | undefined = undefined

  private userDoc: AngularFirestoreDocument<UserInterface>;
  user: Observable<UserInterface>;

  private userSettingsDoc: AngularFirestoreDocument<SettingsInterface>;
  userSettings: Observable<SettingsInterface>;

  private userPersonalDetailsDoc: AngularFirestoreDocument<PersonalDetailsInterface>;
  userPersonalDetails: Observable<PersonalDetailsInterface>;

  private userQualificationsEducationDoc: AngularFirestoreDocument<QualificationsEducationInterface>;
  userQualificationsEducation: Observable<QualificationsEducationInterface>;

  private userRewardsDoc: AngularFirestoreDocument<RewardsInterface>;
  userRewards: Observable<RewardsInterface>;

  private userCoinsDoc: AngularFirestoreDocument<CoinsInterface>;
  userCoins: Observable<CoinsInterface> | undefined = undefined;

  private userEventsDoc: AngularFirestoreDocument<EventsInterface>;
  userEvents: Observable<EventsInterface> | undefined = undefined;

  private postContactInfoDoc: AngularFirestoreDocument<ContactInfoInterface>;
  // postContactInfo: Observable<ContactInfoInterface>;

  private featuredCol: AngularFirestoreCollection<OfferInterface[]>;
  private travelCol: AngularFirestoreCollection<OfferInterface[]>;
  private foodCol: AngularFirestoreCollection<OfferInterface[]>;
  private clothingCol: AngularFirestoreCollection<OfferInterface[]>;
  private cosmeticsCol: AngularFirestoreCollection<OfferInterface[]>;
  private educationCol: AngularFirestoreCollection<OfferInterface[]>;
  private servicesCol: AngularFirestoreCollection<OfferInterface[]>;
  private otherCol: AngularFirestoreCollection<OfferInterface[]>;

  featured: Observable<any> | undefined = undefined;
  travel: Observable<any> | undefined = undefined;
  food: Observable<any> | undefined = undefined;
  clothing: Observable<any> | undefined = undefined;
  cosmetics: Observable<any> | undefined = undefined;
  education: Observable<any> | undefined = undefined;
  services: Observable<any> | undefined = undefined;
  other: Observable<any> | undefined = undefined;

  recaptchaVerifier: firebase.auth.RecaptchaVerifier;
  smsSentConfirmation;

  private exclusiveRewardsCol: AngularFirestoreCollection<any>;
  exclusiveRewardsObs: Observable<any> | undefined = undefined;

  private nearbyPostsCol: AngularFirestoreCollection<any>;
  nearbyPostsObs: Observable<any> | undefined = undefined;

  private nearbyUsersCol: AngularFirestoreCollection<any>;
  nearbyUsersObs: Observable<any> | undefined = undefined;

  private upcomingCoursesCol: AngularFirestoreCollection<any>;
  upcomingCoursesObs: Observable<any> | undefined = undefined;

  private blogPostsCol: AngularFirestoreCollection<any>;
  blogPostsObs: Observable<any> | undefined = undefined;

  private adsCol: AngularFirestoreCollection<any>;
  adsObs: Observable<any> | undefined = undefined;
  adsList = [];
  randomAd: any | undefined = undefined;
  excludedList = [];

  landingPageData: any;
  prevLandingPageData: any = undefined;
  adEnterPage: any | undefined = undefined;
  allPageDataRewards: any;
  allPageDataTasks: any;
  allPageDataEvents: any;
  allPageDataBlogs: any;
  allPageDataAds: any;
  redeemData: any;
  eventRedeemData: any;

  adEnterPageActive = false;

  showNotificationSymbol = false;

  constructor(
    private db: AngularFirestore,
    private au: AngularFireAuth,
    //private st: AngularFireStorage,
    //private fu: AngularFireFunctions,
    private router: Router,
    private navCtrl: NavController,
    private location: Location,
    public getAddress: GetAddressService,
    public toastController: ToastController,
    private afMessaging: AngularFireMessaging
  ) {
    //// Get auth data, then get firestore user document || null
    this.user = this.au.authState.pipe(switchMap(user => {
      if (user) {
        return this.db.doc<UserInterface>(`users/${user.uid}`).valueChanges()
      } else {
        return of(null)
      }
    }));
    /*this.user.pipe(take(1)).subscribe((userData) => {
      if(userData.active && userData.step === 5) {
        // You can now call login function.
        const apiKey = environment.cometChat.api;
        const uid = userData.uid;
        CometChat.login(uid, apiKey).then((user) => {
          console.log("Login Successful:", { user });
        }, (error) => {
          this.fireSwal('Error!', error.message, 'error');  
        });
      }
    });*/
    //this.getDeviceInfo();
    // fade out and remove the #splash-screen
    const splash = document.getElementById('splash-screen');
    if(splash != null) {
      splash.style.opacity = '0'
      setTimeout(() => { splash.remove() }, 300)
    }
    // get ads and store
    this.adsObs = this.returnColData('ads', 'ads', 'active', '==', true);
    this.adsObs.pipe(take(1)).subscribe((adData) => {
      this.adsList = adData;
    })
  }

  ngOnInit() {
    
  }

  async presentToast(message) {
    const toast = await this.toastController.create({
      message: message,
      position: "top",
      buttons: [
        {
          icon: "close-circle",
          role: "cancel"
        }
      ]
    });
    toast.present();
  }

  showMessages() {
    this.afMessaging.messages.subscribe((msg) => {
      const body: any = (msg as any).notification.body;
      const title: any = (msg as any).notification.title;
      this.presentToast(title + ': ' + body);
    });
  }

  getRandomAd(excludeAd) {
    if(this.adsList.length != 0) {
      // ads that have been viewed shouldnt be viewable again in this session unless all ads have been shown
      // ad with id excludeAd shouldnt be shown as we are currently on that ads landing page
      // generated num shouldnt
      if(this.adsList.length == this.excludedList.length) {
        this.excludedList = [];
      }
      if(excludeAd && !this.excludedList.includes(excludeAd)) {
        this.excludedList.push(excludeAd);
      }
      this.randomAd = this.getRandomWithManyExclusions(this.adsList, this.excludedList, excludeAd);
    } else {
      this.randomAd = undefined;
    }
  }

  getRandomWithManyExclusions(list, excludedList, id) {
    let finalItem;
    if(excludedList.length != 0) {
      const arrayWithValuesRemoved = list.filter((value) => !excludedList.includes(value.id));
      const num = Math.floor(Math.random() * arrayWithValuesRemoved.length);
      finalItem = arrayWithValuesRemoved[num];
    } else {
      // any number except the item of id
      let excludedIndex = [];
      let rand = Math.floor(Math.random() * list.length);
      if(list[rand].id == id) {
        // need another item besides this one
        const finalArray = list.filter((value) => list.includes(list[rand]));
        finalItem = finalArray[0];
      } else {
        finalItem = list[rand];
      }
    }
    this.excludedList.push(finalItem.id);
    return finalItem;
  }

  /*async getDeviceInfo() {
    this.deviceInfo = await Device.getInfo();
    if(this.deviceInfo.model == 'IPhone' && Number(this.deviceInfo.osVersion.substring(0,2)) < 11) {
      this.oldIphone = true;
      this.fireSwal('Old Device', 'You are currently using an old IPhone which is below version 11. Please consider upgrading the version or using a newer device to get the most out of Yusra App.', 'info');
    }
  }*/

  //Auth management

  createUser(email, password, mailingList) {
    return new Promise((resolve, reject) => { // resolves: { status: boolean, data: {} || '' }
      this.au.auth.createUserWithEmailAndPassword(email, password).catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode == 'auth/weak-password') {
          resolve({ status: false, data: 'The password is too weak.' });
        } else {
          resolve({ status: false, data: errorMessage });
        }
      }).then((data) => {
        if(data !== undefined) {
          var newUserObj = new User(data['user']['uid'], email, mailingList);
          var obj1 = newUserObj.createNewUser();
          var obj2 = newUserObj.createEmptyUserPersonalDetails();
          var settings = newUserObj.createUserSettings();
          var postInfo = newUserObj.createPostInfo();
          this.db.collection('users').doc(data['user']['uid']).set(obj1)
          .then(() => {
            // personalDetails sub collection aded when user signs up
            this.db.collection(`users/${data['user']['uid']}/personalDetails`).doc('personalDetails').set(obj2).then(() => {
              //settings sub collection added when user signs up
              this.db.collection(`users/${data['user']['uid']}/settings`).doc('settings').set(settings).then(() => {
                this.db.collection(`users/${data['user']['uid']}/postInfo`).doc('postInfo').set(postInfo).then(() => {
                  this.db.collection(`users/${data['user']['uid']}/permissions`).doc('user').set({ val: true }).then(() => {
                    this.db.collection(`users/${data['user']['uid']}/coins`).doc('coins').set({ total: 0, customerId: '' }).then(() => {
                      var resp = this.login(email, password);
                      if(resp) {
                        this.getUser(data['user']['uid']);
                        this.au.auth.currentUser.sendEmailVerification();
                        resolve({ status: true, data: 'User successfully created!' });
                      } else {
                        resolve({ status: false, data: 'User was not logged in!' });
                      }
                    })
                    .catch((error) => {
                      resolve({ status: false, data: error.message });
                    });
                  })
                  .catch((error) => {
                      resolve({ status: false, data: error.message });
                  });
                })
                .catch((error) => {
                    resolve({ status: false, data: error.message });
                });
              })
              .catch((error) => {
                  resolve({ status: false, data: error.message });
              });
            })
            .catch((error) => {
                resolve({ status: false, data: error.message });
            });
          })
          .catch((error) => {
              resolve({ status: false, data: error.message });
          });
        } else {
          resolve({ status: false, data: 'User was not created!' });
        }
      })
    });
  }

  login(email, password) {
    return new Promise((resolve, reject) => {
      //this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider());
      this.au.auth.signInWithEmailAndPassword(email, password).then((data) => {
        if(data !== undefined) {
          this.getUser(data['user']['uid']);
          resolve({ status: true, data: 'User successfully logged in!' });
        } else {
          resolve({ status: false, data: 'User unable to sign in!' });
        }
      }).catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        if (errorCode === 'auth/wrong-password') {
          resolve({ status: false, data: 'Wrong password.' });
        } else {
          resolve({ status: false, data: errorMessage });
        }
      })
    });
  }

  logout() {
    this.au.auth.signOut();
  }

  isUserLoggedIn() {
    return new Promise((resolve, reject) => {
      this.au.auth.onAuthStateChanged((user) => {
        if (user) {
          resolve(user.uid);
        } else {
          resolve(false);
        }
      });
    });
  }

  isUserLoggedInGetObject() {
    return new Promise((resolve, reject) => {
      this.au.auth.onAuthStateChanged((user) => {
        if (user) {
          resolve(user);
        } else {
          resolve(false);
        }
      });
    });
  }

  getUser(uid) {
    this.userDoc = this.db.doc<UserInterface>(`users/${uid}`);
    this.user = this.userDoc.valueChanges();
  }

  retUser(uid) {
    const userDoc = this.db.doc<UserInterface>(`users/${uid}`);
    const userObs = userDoc.valueChanges();
    return userObs;
  }

  getUserAuth() {
    return this.au.auth.currentUser;
  }

  getPostUserType(uid) {
    return new Promise((resolve, reject) => {
      let userDoc = this.db.doc<UserInterface>(`users/${uid}`);
      let user = userDoc.valueChanges();
      resolve(user);
    });
  }

  getRedeemDetails() {
    return new Promise((resolve, reject) => {
      this.isUserLoggedIn().then((uid) => {
        if (uid) {
          // promise for Personal Details
          const prom1 = new Promise((res1, rej1) => {
            // All docs
            const personalDetailsFullNameDoc = this.db.doc(`users/${uid}/personalDetails/fullName`);
            const personalDetailsGenderLocationDoc = this.db.doc(`users/${uid}/personalDetails/genderLocation`);
            const personalDetailsDoc = this.db.doc(`users/${uid}/personalDetails/personalDetails`);
            // make into observables
            const personalDetailsFullNameObs = personalDetailsFullNameDoc.valueChanges();
            const personalDetailsGenderLocationObs = personalDetailsGenderLocationDoc.valueChanges();
            const personalDetailsObs = personalDetailsDoc.valueChanges();
            // subscribe and extract data
            personalDetailsFullNameObs.pipe(take(1)).subscribe((personalDetailsFullNameData: any) => {
              const fullNameDataObj = {
                firstName: personalDetailsFullNameData.firstName,
                lastName: personalDetailsFullNameData.lastName
              };
              personalDetailsGenderLocationObs.pipe(take(1)).subscribe((personalDetailsGenderLocationData: any) => {
                const genderDataObj = {
                  gender: personalDetailsGenderLocationData.gender
                };
                const locationDataObj = {
                  city: personalDetailsGenderLocationData.city,
                  country: personalDetailsGenderLocationData.country,
                  postCode: personalDetailsGenderLocationData.postCode
                };
                personalDetailsObs.pipe(take(1)).subscribe((personalDetailsData: any) => {
                  const personalDetailsAddressDataObj = {
                    addressLine1: personalDetailsData.addressLine1,
                    addressLine2: personalDetailsData.addressLine2,
                    addressLine3: personalDetailsData.addressLine3,
                  };
                  const personalDetailsDataObj = {
                    contact: personalDetailsData.contact,
                    dob: personalDetailsData.dob,
                    email: personalDetailsData.email,
                    title: personalDetailsData.title
                  };
                  const finalPersonalDetailsObj = {...fullNameDataObj, ...genderDataObj, ...personalDetailsDataObj};
                  const finalAddressDetailsObj = {...personalDetailsAddressDataObj, ...locationDataObj};
                  res1({ finalPersonalDetails: finalPersonalDetailsObj, finalAddressDetails: finalAddressDetailsObj });
                });
              });
            });
          });
          prom1.then((PersonalDetailsObj: any) => {
            const finalObj = {
              personalDetails: PersonalDetailsObj.finalPersonalDetails,
              addressDetails: PersonalDetailsObj.finalAddressDetails,
              uid: uid
            };
            resolve(finalObj);
          });
        } else {
          console.error('user not logged in');
          reject(false);
        }
      });
    });
  }

  getProfileDetails() {
    return new Promise((resolve, reject) => {
      this.isUserLoggedIn().then((uid) => {
        if (uid) {
          // promise for Personal Details
          const prom1 = new Promise((res1, rej1) => {
            // All docs
            const personalDetailsFullNameDoc = this.db.doc(`users/${uid}/personalDetails/fullName`);
            const personalDetailsGenderLocationDoc = this.db.doc(`users/${uid}/personalDetails/genderLocation`);
            const personalDetailsDoc = this.db.doc(`users/${uid}/personalDetails/personalDetails`);
            // make into observables
            const personalDetailsFullNameObs = personalDetailsFullNameDoc.valueChanges();
            const personalDetailsGenderLocationObs = personalDetailsGenderLocationDoc.valueChanges();
            const personalDetailsObs = personalDetailsDoc.valueChanges();
            // subscribe and extract data
            personalDetailsFullNameObs.pipe(take(1)).subscribe((personalDetailsFullNameData: any) => {
              const fullNameDataObj = {
                firstName: personalDetailsFullNameData.firstName,
                lastName: personalDetailsFullNameData.lastName
              };
              personalDetailsGenderLocationObs.pipe(take(1)).subscribe((personalDetailsGenderLocationData: any) => {
                const genderDataObj = {
                  gender: personalDetailsGenderLocationData.gender
                };
                const locationDataObj = {
                  city: personalDetailsGenderLocationData.city,
                  country: personalDetailsGenderLocationData.country,
                  postCode: personalDetailsGenderLocationData.postCode
                };
                personalDetailsObs.pipe(take(1)).subscribe((personalDetailsData: any) => {
                  const personalDetailsAddressDataObj = {
                    addressLine1: personalDetailsData.addressLine1,
                    addressLine2: personalDetailsData.addressLine2,
                    addressLine3: personalDetailsData.addressLine3,
                  };
                  const personalDetailsDataObj = {
                    contact: personalDetailsData.contact,
                    dob: personalDetailsData.dob,
                    email: personalDetailsData.email,
                    title: personalDetailsData.title
                  };
                  const finalPersonalDetailsObj = {...fullNameDataObj, ...genderDataObj, ...personalDetailsDataObj};
                  const finalAddressDetailsObj = {...personalDetailsAddressDataObj, ...locationDataObj};
                  res1({ finalPersonalDetails: finalPersonalDetailsObj, finalAddressDetails: finalAddressDetailsObj });
                });
              });
            });
          });
          // promise for qualificationEducationDetails
          const prom2 = new Promise((res2, rej2) => {
            // All docs
            const qualificationsEducationDoc = this.db.doc(`users/${uid}/qualificationsEducation/qualificationsEducation`);
            const qualificationsEducationPrivateDoc = this.db.doc(`users/${uid}/qualificationsEducation/private`);
            // make into observables
            const qualificationsEducationObs = qualificationsEducationDoc.valueChanges();
            const qualificationsEducationPrivateObs = qualificationsEducationPrivateDoc.valueChanges();
            // subscribe and extract data
            qualificationsEducationObs.pipe(take(1)).subscribe((qualificationsEducationData: any) => {
              const qualificationsEducationObj = {
                employed: qualificationsEducationData.employed,
                industry: qualificationsEducationData.industry,
                jobList: qualificationsEducationData.jobList,
                opportunities: qualificationsEducationData.opportunities,
                qualification: qualificationsEducationData.qualification,
                summary: qualificationsEducationData.summary
              };
              qualificationsEducationPrivateObs.pipe(take(1)).subscribe((qualificationsEducationPrivateData: any) => {
                const qualificationsEducationPrivateObj = {
                  cv: qualificationsEducationPrivateData.cv,
                  profilePicture: qualificationsEducationPrivateData.profilePicture
                };
                const finalQualificationsEducationObj = {...qualificationsEducationObj, ...qualificationsEducationPrivateObj};
                res2({ finalQualificationsEducationDetails: finalQualificationsEducationObj });
              });
            });
          });
          // promise for additionalDetails
          const prom3 = new Promise((res3, rej3) => {
            // All docs
            const additionalDetailsDoc = this.db.doc(`users/${uid}/additionalDetails/additionalDetails`);
            // make into observables
            const additionalDetailsObs = additionalDetailsDoc.valueChanges();
            // subscribe and extract data
            additionalDetailsObs.pipe(take(1)).subscribe((additionalDetailsData: any) => {
              const finalAdditionalDetailsObj = {
                children: additionalDetailsData.children,
                ethnicity: additionalDetailsData.ethnicity,
                interestedInVolunteering: additionalDetailsData.interestedInVolunteering,
                maritalStatus: additionalDetailsData.maritalStatus,
                referral: additionalDetailsData.referral,
                religion: additionalDetailsData.religion
              };
              res3({ finalAdditionalDetails: finalAdditionalDetailsObj });
            });
          });
          // promise for postInfo
          const prom4 = new Promise((res4, rej4) => {
            // All docs
            const postInfoDoc = this.db.doc(`users/${uid}/postInfo/postInfo`);
            // make into observables
            const postInfoObs = postInfoDoc.valueChanges();
            // subscribe and extract data
            postInfoObs.pipe(take(1)).subscribe((postInfoData: any) => {
              const finalPostInfoObj = {
                anonymous: postInfoData.anonymous,
                awaitingDbs: postInfoData.awaitingDbs,
                dbsVerified: postInfoData.dbsVerified,
                summary: postInfoData.summary,
                type: postInfoData.type,
                uid: postInfoData.uid
              };
              res4({ finalPostInfo: finalPostInfoObj });
            });
          });
          // promise for otherDetails
          const prom5 = new Promise((res5, rej5) => {
            // All docs
            const otherDetailsDoc = this.db.doc(`users/${uid}`);
            // make into observables
            const otherDetailsObs = otherDetailsDoc.valueChanges();
            // subscribe and extract data
            otherDetailsObs.pipe(take(1)).subscribe((otherDetailsData: any) => {
              const finalOtherDetailsObj = {
                anonymous: otherDetailsData.anonymous,
                languages: otherDetailsData.languages,
                emailVerified: otherDetailsData.emailVerified,
                mailingList: otherDetailsData.mailingList,
                uid: otherDetailsData.uid
              };
              res5({ finalOtherDetails: finalOtherDetailsObj });
            });
          });
          prom1.then((PersonalDetailsObj: any) => {
            // resolved promise for qualificationEducationDetails
            prom2.then((QualificationsEducationObj: any) => {
              // resolved promise for additionalDetails
              prom3.then((AdditionalDetailsObj: any) => {
                // resolved promise for postInfo
                prom4.then((PostInfoObj: any) => {
                  // resolved promise for otherDetails
                  prom5.then((OtherDetailsObj: any) => {
                    const finalObj = {
                      personalDetails: PersonalDetailsObj.finalPersonalDetails,
                      addressDetails: PersonalDetailsObj.finalAddressDetails,
                      qualificationEducationDetails: QualificationsEducationObj.finalQualificationsEducationDetails,
                      additionalDetails: AdditionalDetailsObj.finalAdditionalDetails,
                      postInfo: PostInfoObj.finalPostInfo,
                      otherDetails: OtherDetailsObj.finalOtherDetails,
                      uid: uid
                    };
                    resolve(finalObj);
                  });
                });
              });
            });
          });
        } else {
          console.error('user not logged in');
          reject(false);
        }
      });
    });
  }

  getUserProfileData(uid) {
    // get anonymous, languages, userType
    // get awaitingDbs, dbsVerified, summary from users/uid/postInfo/postInfo
    // get gender, city, postCode, country from users/uid/personalDetails/genderLocation
    // User Reviews, Completed Tasks, rewardsList <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
    return new Promise((resolve, reject) => {
      // All docs
      const userMainDoc = this.db.doc(`users/${uid}`);
      const postInfoDoc = this.db.doc(`users/${uid}/postInfo/postInfo`);
      const genderLocationDoc = this.db.doc(`users/${uid}/personalDetails/genderLocation`);
      // make into observables
      const userMainObs = userMainDoc.valueChanges();
      const postInfoObs = postInfoDoc.valueChanges();
      const genderLocationObs = genderLocationDoc.valueChanges();
      // Create list of Observables
      const obsList = [userMainObs, postInfoObs, genderLocationObs];
      // Check if anonymous or not
      userMainObs.pipe(take(1)).subscribe((userMainData: any) => {
        const anonymous = userMainData.anonymous;
        if(anonymous) {
          this.mergeObs(obsList, true).then((mergeResp: Observable<any>) => {
            resolve(mergeResp);
          }).catch((error) => {
            reject(error);
          })
        } else {
          // If anonymous is false get firstName, lastName, profilePicture from users/uid/postInfo/private 
          const anonymousDoc = this.db.doc(`users/${uid}/postInfo/private`);
          const anonymousObs = anonymousDoc.valueChanges();
          // add this new observable to obsList
          obsList.push(anonymousObs);
          this.mergeObs(obsList, false).then((mergeResp: Observable<any>) => {
            resolve(mergeResp);
          }).catch((error) => {
            reject(error);
          })
        }
      })
    });
  }

  mergeObs(obsList, anonymous) {
    return new Promise((resolve, reject) => {
      const main: Observable<any> = obsList[0];
      const postInfo: Observable<any> = obsList[1];
      const genderLocation: Observable<any> = obsList[2];
      if(anonymous) {
        const finalObs = zip(main, postInfo, genderLocation).pipe(take(1));
        resolve(finalObs);
      } else {
        const anonymousObs = obsList[3];
        const finalObs = zip(main, postInfo, genderLocation, anonymousObs).pipe(take(1));
        resolve(finalObs);
      }
    });
  }

  getUserData(cap, low) {
    return new Promise((resolve, reject) => {
      this.isUserLoggedIn().then((uid) => {
        if(uid) {
          this['user' + cap + 'Doc'] = this.db.doc(`users/${uid}/${low}/${low}`);
          this['user' + cap] = this['user' + cap + 'Doc'].valueChanges();
          resolve(this['user' + cap]);
        } else {
          console.error('user not logged in');
          resolve(false);
        }
      })
    });
  }

  getUserDataWithUid(cap, low, uid) {
    return new Promise((resolve, reject) => {
      this['user' + cap + 'Doc'] = this.db.doc(`users/${uid}/${low}/${low}`);
      this['user' + cap] = this['user' + cap + 'Doc'].valueChanges();
      resolve(true);
    });
  }

  retUserDataUnique(col, low, low2, uid) {
    return new Promise((resolve, reject) => {
      const custDoc = this.db.doc(`${col}/${uid}/${low}/${low2}`);
      const custObs = custDoc.valueChanges();
      resolve(custObs);
    });
  }

  retUserDataUnique2(col, low, low2) {
    return new Promise((resolve, reject) => {
      this.isUserLoggedIn().then((uid) => {
        const custDoc = this.db.doc(`${col}/${uid}/${low}/${low2}`);
        const custObs = custDoc.valueChanges();
        resolve(custObs);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getUserDataRetUid(cap, low) {
    return new Promise((resolve, reject) => {
      this.isUserLoggedIn().then((uid) => {
        if(uid) {
          this['user' + cap + 'Doc'] = this.db.doc(`users/${uid}/${low}/${low}`);
          this['user' + cap] = this['user' + cap + 'Doc'].valueChanges();
          resolve(uid);
        } else {
          console.error('user not logged in');
          resolve(false);
        }
      })
    });
  }

  getUserDataForPost(uid, acceptedId) {
    return new Promise((resolve, reject) => {
      let postInfoDoc = this.db.doc(`users/${uid}/postInfo/postInfo`);
      let postInfoObs = postInfoDoc.valueChanges();
      postInfoObs.pipe(take(1)).subscribe((postInfoData: PostInfoInterface) => {
        let finalObj: PostInfoFinalInterface = {
          anonymous: postInfoData.anonymous,
          summary: postInfoData.summary,
          dbsVerified: postInfoData.dbsVerified,
          awaitingDbs: postInfoData.awaitingDbs,
          type: postInfoData.type,
          uid: postInfoData.uid,
          firstName: '',
          lastName: '',
          profilePicture: ''
        }
        if(postInfoData.anonymous && !acceptedId) {
          resolve(finalObj);
        } else {
          let postInfoPrivateDoc = this.db.doc(`users/${uid}/postInfo/private`);
          let postInfoPrivateObs = postInfoPrivateDoc.valueChanges();
          postInfoPrivateObs.pipe(take(1)).subscribe((postInfoPrivateData: PostInfoPrivateInterface) => {
            finalObj.firstName = postInfoPrivateData.firstName;
            finalObj.lastName = postInfoPrivateData.lastName;
            finalObj.profilePicture = postInfoPrivateData.profilePicture;
            resolve(finalObj);
          })
        }
      })
    });
  }

  setUserData(col, subCol, subDoc, obj) {
    return new Promise((resolve, reject) => {
      this.isUserLoggedIn().then((uid) => {
        if(uid) {
          this.db.doc(`${col}/${uid}/${subCol}/${subDoc}`).set(obj, { merge: true })
          .then(() => {
              resolve({ status: true, data: "Document successfully written!" });
          })
          .catch((error) => {
              resolve({ status: false, data: error });
          });
        } else {
          console.error('user not logged in');
          resolve({ status: false, data: 'User not logged in'});
        }
      })
    });
  }

  checkVerified() {
    return new Promise((resolve, reject) => {
      this.au.auth.onAuthStateChanged((user) => {
        if (user.emailVerified) {
          this.db.collection('users').doc(user.uid).update({ emailVerified: true });
          resolve(true);
        } else {
          this.sendEmailVerification();
          resolve(false);
        }
      });
    });
  }

  sendEmailVerification() {
    this.au.auth.currentUser.sendEmailVerification();
  }

  joinMailingList(email) {
    // need to connect to mailchimp!!! <<<<<<<<<<<<<<<<<<<<<<<<<
  }

  resetPassword(email) {
    return new Promise((resolve, reject) => {
      this.au.auth.sendPasswordResetEmail(email).then(function() {
        resolve({ status: true, data: 'Please check your inbox for our password reset email!' });
      }).catch((error) => {
        resolve({ status: false, data: error.message });
      });
    })
  }

  // Set Data

  updateDoc(col, id, obj) {
    return new Promise((resolve, reject) => {
      this.db.collection(col).doc(id).update(obj)
      .then(() => {
          resolve({ status: true, data: "Document successfully written!" });
      })
      .catch((error) => {
          resolve({ status: false, data: error });
      });
    });
  }

  setMergeDocNew(col, id, obj) {
    return new Promise((resolve, reject) => {
      this.db.doc(`${col}/${id}`).set(obj, { merge: true })
      .then(() => {
          resolve(true);
      })
      .catch((error) => {
          reject(error);
      });
    });
  }

  //sets a new document with the name of the sub collection
  addDoc(col, subcol, id, obj, step) {
    return new Promise((resolve, reject) => {
      this.db.collection(`${col}/${id}/${subcol}`).doc(subcol).set(obj)
      .then(() => {
        if(step) {
          let innerObj: any = { step: step };
          this.db.collection(col).doc(id).update(innerObj).then(() => {
            resolve({ status: true, data: "Document successfully written!" });
          })
          .catch((error) => {
              resolve({ status: false, data: error });
          });
        } else {
          resolve({ status: true, data: "Document successfully written!" });
        }
      })
      .catch((error) => {
          resolve({ status: false, data: error });
      });
    });
  }

  addDoc2(col, subcol, subcol2, id, obj, step) {
    return new Promise((resolve, reject) => {
      this.db.collection(`${col}/${id}/${subcol}`).doc(subcol2).set(obj)
      .then(() => {
        if(step) {
          let innerObj: any = { step: step };
          this.db.collection(col).doc(id).update(innerObj).then(() => {
            resolve({ status: true, data: "Document successfully written!" });
          })
          .catch((error) => {
              resolve({ status: false, data: error });
          });
        } else {
          resolve({ status: true, data: "Document successfully written!" });
        }
      })
      .catch((error) => {
          resolve({ status: false, data: error });
      });
    });
  }

  // also used for adding new notifications
  addUniqueDoc(col, subcol, id, obj) {
    return new Promise((resolve, reject) => {
      this.db.collection(`${col}/${id}/${subcol}`).add(obj).then((ref) => {
        this.db.collection(`${col}/${id}/${subcol}`).doc(ref.id).set({ id: ref.id }, { merge: true }).then(() => {
          resolve({ status: true, data: "Document successfully written!" });
        }).catch((error) => {
          resolve({ status: false, data: error });
        });
      }).catch((error) => {
        resolve({ status: false, data: error });
      });
    });
  }

  addSubDoc(col, subcol, subcol2, id, obj) {
    return new Promise((resolve, reject) => {
      this.db.collection(`${col}/${id}/${subcol}`).doc(subcol2).set(obj)
      .then(() => {
        resolve({ status: true, data: "Document successfully written!" });
      })
      .catch((error) => {
        resolve({ status: false, data: error });
      });
    });
  }

  setMergeSubDoc(col, subcol, subcol2, id, obj) {
    return new Promise((resolve, reject) => {
      this.db.collection(`${col}/${id}/${subcol}`).doc(subcol2).set(obj, { merge: true })
      .then(() => {
        resolve({ status: true, data: "Document successfully written!" });
      })
      .catch((error) => {
        resolve({ status: false, data: error });
      });
    });
  }

  // Navigation

  navTo(val) {
    //this.router.navigateByUrl(val);
    this.navCtrl.navigateRoot(val);
  }

  navToProm(val) {
    return new Promise((resolve, reject) => {
      this.navCtrl.navigateRoot(val).then(() => {
        resolve(true);
      }).catch((error) => {
        this.fireSwal('Error', error.message, 'error');
      })
    });
  }

  navToId(val, id) {
    //this.router.navigate(['home/tabs/'+val+'/'+id]);
    this.navCtrl.navigateRoot('home/tabs/'+val+'/'+id);
  }

  navBack() {
    this.location.back();
  }

  // Swal

  fireSwal(title, text, type) {
    Swal.fire({
      title: title,
      text: text,
      type: type
    })
  }

  // Orgs
  getOrgData() {
    return new Promise((resolve, reject) => {
      let orgCol = this.db.collection('orgs', ref => ref.where('active', '==', true).where('participating', '==', true).orderBy('orgName'));
      resolve(orgCol.valueChanges());
    });
  }

  // Rewards
  getFeatured(col) {
    this.featuredCol = this.db.collection(col, ref => ref.where('active', '==', true).where('featured', '==', true).orderBy('order'));
    this.featured = this.featuredCol.valueChanges();
  }
  getRewards(cat, col) {
    if(this[cat] == undefined) {
      this[cat+'Col'] = this.db.collection(col, ref => ref.where('active', '==', true).where('category', '==', cat));
      this[cat] = this[cat+'Col'].valueChanges();
    }
  }
  getReward(id) {
    return new Promise((resolve, reject) => {
      const rewardCol = this.db.collection('offers', ref => ref.where('active', '==', true).where('id', '==', id));
      const rewardObs = rewardCol.valueChanges();
      resolve(rewardObs);
    });
  }
  retRewards(cat, col) {
    if(this[cat] == undefined) {
      if(cat === 'corona') {
        this[cat+'Col'] = this.db.collection(col, ref => ref.where('active', '==', true).where('category', 'in', ['confirmed corona', 'isolate corona']));
        return this[cat+'Col'].valueChanges();
      } else {
        this[cat+'Col'] = this.db.collection(col, ref => ref.where('active', '==', true).where('category', '==', cat));
        return this[cat+'Col'].valueChanges();
      }
    }
  }

  // Posts

  getPostContactInfo(postId, securityBypass, uid) {
    return new Promise((resolve, reject) => {
      if(securityBypass) {
        this.db.doc(`users/${uid}`).set({ currentPostId: postId }, { merge: true }).then(() => {
          this.doPostContactInfo(postId).then((contactInfo) => {
            resolve(contactInfo);
          })
        });
      } else {
        this.doPostContactInfo(postId).then((contactInfo) => {
          resolve(contactInfo);
        })
      }
    });
  }

  doPostContactInfo(postId) {
    return new Promise((resolve, reject) => {
      let postContactInfoDoc = this.db.doc<ContactInfoInterface>(`posts/${postId}/contactInfo/contactInfo`);
      let postContactInfo = postContactInfoDoc.valueChanges();
      postContactInfo.pipe((take(1))).subscribe((contactInfo) => {
        resolve(contactInfo);
      })
    });
  }

  //recaptcha
  activateInvisibleRecaptcha() {
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        console.log('recaptcha response: ', response);
      }
    });
  }

  activateInvisibleRecaptchaSignup() {
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('sign-in-button', {
      'size': 'invisible',
      'callback': (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        console.log('recaptcha response: ', response);
      },
      'expired-callback': () => {
        console.log("expired-callback");
      }
    });
  }

  sendVerificationSMS(phoneNumber) {
    this.au.auth.signInWithPhoneNumber(phoneNumber, this.recaptchaVerifier).then((confirmationResult) => {
      // SMS sent. Prompt user to type the code from the message, then sign the
      // user in with confirmationResult.confirm(code).
      this.smsSentConfirmation = confirmationResult;
    }).catch((error) => {
      // Error; SMS not sent
      // ...
      console.error('SMS not sent: ', error);
      this.fireSwal('SMS Not Sent', error.message, 'error');
      this.recaptchaVerifier.render().then((widgetId) => {
        this.recaptchaVerifier['reset'](widgetId);
      });
    });
  }

  sendVerificationSMSSignup(phoneNumber) {
    return new Promise((resolve, reject) => {
      this.au.auth.signInWithPhoneNumber(phoneNumber, this.recaptchaVerifier).then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        this.smsSentConfirmation = confirmationResult;
        resolve(true);
      }).catch((error) => {
        // Error; SMS not sent
        // ...
        this.recaptchaVerifier.render().then((widgetId) => {
          this.recaptchaVerifier['reset'](widgetId);
          reject(error);
        }).catch((error2) => {
          reject(error2);
        });
      });
    });
  }

  verifyCode(code, email, pw) {
    return new Promise((resolve, reject) => {
      // Get reference to the currently signed-in user
      let prevUser = this.au.auth.currentUser;
      //sign in user using phone
      this.smsSentConfirmation.confirm(code).then((result) => {
        //let credential = firebase.auth.PhoneAuthProvider.credential(this.smsSentConfirmation.verificationId, code);
        // User signed in successfully using phone.
        let currentUser = result.user;
        // After data is migrated delete the duplicate user
        currentUser.delete().then(() => {
          // Sign in using email and pw
          this.login(email, pw).then((data: { status: boolean, data: any }) => {
            if(data.status) {
              this.fireSwal('Success!', 'Your phone number has been verified!', 'success');
              resolve(true);
            } else {
              this.fireSwal('Error!', data.data, 'error');
              resolve(false);
            }
          });
        });
      }).catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
        this.fireSwal('Incorrect', error.message, 'error');
        resolve(false);
      });
    });
  }

  onIdTokenRevocation(email, password) {
    return new Promise((resolve, reject) => {
      let credential = firebase.auth.EmailAuthProvider.credential(email, password);
      this.au.auth.currentUser.reauthenticateWithCredential(credential)
      .then(result => {
        resolve({ status: true, data: result });
      })
      .catch(error => {
        resolve({ status: false, data: error });
      });
    });
  }

  async getFullNameAdmin(uid) {
    const res = await fetch('https://europe-west2-yusra-app.cloudfunctions.net/retFullName', {
        method: 'POST',
        body: JSON.stringify({ uid })
    });
    const data = await res.json();
    data.body = JSON.parse(data.body);
    return data.body;
  }

  checkVolunteerReferralCode(code) {
    return new Promise((resolve, reject) => {
      let usersGroup = this.db.collection('users', ref => ref.where('userType', 'in', ['volunteer', 'admin'])).valueChanges();
      usersGroup.pipe(take(1)).subscribe((querySnapshot) => {
        let queryObj = { found: false, firstName: '', lastName: '' };
        let prom = new Promise(async(res, rej) => {
          let index = 0;
          for(let doc of querySnapshot) {
            if(doc['uid'] == code) {
              await this.getFullNameAdmin(code).then((fullNameData) => {
                if(!fullNameData['error']) {
                  queryObj = { found: true, firstName: fullNameData['firstName'], lastName: fullNameData['lastName'] };
                }
                res(queryObj);
              });
            }
            if(index == querySnapshot.length-1) {
              res(queryObj);
            }
            index++;
          }
        });
        prom.then((promData: { found: boolean, firstName: string, lastName: string }) => {
          if(promData.found) {
            resolve({ status: true, data: promData });
          } else {
            resolve({ status: false, data: promData });
          }
        })
      });
    });
  }

  addNewDocToUsersNotificationCollection(obj) {
    this.db.collection(`users/${obj.uid}/notifications`).add(obj).then((ref) => {
      this.db.collection(`users/${obj.uid}/notifications`).doc(ref.id).update({ id: ref.id }); // updated the doc that was created with its newly generated doc ID
    });
  }

  getPersonalDetails(userId) {
    return new Promise((resolve, reject) => {
      let contactDoc = this.db.doc(`users/${userId}/personalDetails/personalDetails`);
      let contactObs = contactDoc.valueChanges();
      let countryDoc = this.db.doc(`users/${userId}/personalDetails/genderLocation`);
      let countryObs = countryDoc.valueChanges();
      contactObs.pipe((take(1))).subscribe((contactData: any) => {
        const contactNumber = contactData.contact;
        countryObs.pipe((take(1))).subscribe((countryData: any) => {
          const country = countryData.country;
          resolve({ contactNumber: contactNumber, country: country });
        });
      });
    });
  }

  // Get different collections

  resetNotificationsBadge(uid) {
    this.db.collection('users').doc(uid).update({ notificationsBadge: 0 });
    this.showNotificationSymbol = false;
  }

  deleteNotification(uid, id) {
    return new Promise((resolve, reject) => {
      this.db.collection(`users/${uid}/notifications`).doc(id).delete().then(() => {
        resolve(true)
      }).catch((error) => {
        this.fireSwal('Error', error.message, 'error');
        resolve(false)
      })
    });
  }

  getColData(col, type, whereField, whereFilter, whereValue) {
    this[type + 'Col'] = this.db.collection(col, ref => ref.where(whereField, whereFilter, whereValue));
    this[type + 'Obs'] = this[type + 'Col'].valueChanges();
  }

  returnColData(col, type, whereField, whereFilter, whereValue) {
    this[type + 'Col'] = this.db.collection(col, ref => ref.where(whereField, whereFilter, whereValue));
    return this[type + 'Col'].valueChanges();
  }

  getPostsData(latGreaterVal, latLessVal, lonGreaterVal, lonLessVal, orderBy, limit) {
    this.nearbyPostsCol = this.db.collection<PostInterface>('posts', ref => ref.where('latitude', '>=', latGreaterVal).where('latitude', '<=', latLessVal).where('active', '==', true).orderBy(orderBy).limit(limit));
    this.nearbyPostsObs = this.nearbyPostsCol.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data() as PostInterface;
        const id = a.payload.doc.id;
        //filter data here lonGreaterVal, lonLessVal
        if(data.longitude >= lonGreaterVal || data.longitude <= lonLessVal) {
          return { id, ...data };
        }
      }))
    );
  }

  getNearbyPosts(radius, lat, lng, country, includeBusinesses) {
    //this.nearbyPostsObs = this.getAddress.getListOfActivePostsByRadius(lat, lng, radius, country);
    const respObs = this.getAddress.getMapboxListFeatures('United Kingdom'); //<<<<<<<< current country should go here
    if(respObs) {
      respObs.pipe(take(1)).subscribe((respData) => {
        this.getPostsByRadius(respData.features, lat, lng, radius, includeBusinesses).then((listOfPostsInRadius) => {
          this.nearbyPostsObs = of(listOfPostsInRadius);
        }).catch((error) => {
          console.log('No nearby posts!');
        });
      });
    } // else this.nearbyPostsObs stays as undefined
  }

  getPostsByRadius(newPostData, lat, lng, miles, includeBusinesses) {

    // If admin then miles should cover the entire country. UK is 600 miles top to bottom. <<<<<<<<
    const currentEmail = this.getUserAuth().email;
    if(currentEmail == 'info@yusraapp.co.uk') { //<<<<<<<<<<<<<<< Admin Email
      miles = 600; //<<<<<<<<<<<<<<<<<<<<<<<<<< UK Only
    }
    return new Promise((resolve, reject) => {
      let finalList = [];
      const km = this.getKiloMetersFromMiles(miles);
      for(let item of newPostData) {
        // console.log('item: ', item);
        if((item.properties.category === 'charity' && includeBusinesses) || (this.arePointsNear({ lng: item.properties.lon, lat: item.properties.lat }, { lng: lng, lat: lat }, km) && (includeBusinesses || (!includeBusinesses && item.properties.category != 'business')))) {
          finalList.push(item);
        }
      }
      if(finalList.length === 0) {
        reject(false);
      } else {
        resolve(finalList);
      }
    });
  }

  getKiloMetersFromMiles(i) {
    return i*1.60934;
  }

  getMilesFromKiloMeters(i) {
    return i*0.621371;
  }

  arePointsNear(checkPoint, centerPoint, km) {
    const ky = 40000 / 360;
    const kx = Math.cos(Math.PI * centerPoint.lat / 180.0) * ky;
    const dx = Math.abs(centerPoint.lng - checkPoint.lng) * kx;
    const dy = Math.abs(centerPoint.lat - checkPoint.lat) * ky;
    const distance = Math.sqrt(dx * dx + dy * dy);
    if(distance <= km) {
      return distance;
    } else {
      return false;
    }
  }

  getPostById(pid) {
    return new Promise((resolve, reject) => {
      const postObs = this.db.doc(`posts/${pid}`).valueChanges();
      postObs.pipe(take(1)).subscribe((postData: any) => {
        if(postData.active) {
          resolve(postData);
        } else {
          reject('This post is awaiting admin approval!');
        }
      });
    });
  }

  getPostByIdToEdit(pid) {
    return new Promise((resolve, reject) => {
      const postObs = this.db.doc(`posts/${pid}`).valueChanges();
      postObs.pipe(take(1)).subscribe((postData: any) => {
        resolve(postData);
      });
    });
  }

  isPostActive(pid) {
    return new Promise((resolve, reject) => {
      const postObs = this.db.doc(`posts/${pid}`).valueChanges();
      postObs.pipe(take(1)).subscribe((postData: any) => {
        if(postData.active || postData.status === 'edit') {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  getPostByFeatured() {
    const featuredCol = this.db.collection('posts', ref => ref.where('active', '==', true).where('featured', '==', true).orderBy('order'));
    const featured = featuredCol.valueChanges();
    featured.pipe(take(1)).subscribe((postData: any) => {
      this['allPageDataTasks'] = postData;
    });
  }

  // store landing page data
  storeLandingPageData(item) {
    this.landingPageData = item;
  }

  storeAllPageData(item, val) {
    this['allPageData'+val] = item;
  }

  storePrevPageData(item) {
    if(item) {
      this.prevLandingPageData = item;
    } else {
      this.prevLandingPageData = 'home';
    }
    
  }

  storeAdEnterPage(tab, id, data) {
    this.adEnterPage = {
      tab: tab,
      id: id,
      data: data
    }
  }

  getLandingPageDataUsingId(col, whereField, whereFilter, whereValue, id) {
    const data = this.db.collection(col, ref => ref.where(whereField, whereFilter, whereValue));
    return data.valueChanges();
  }

  // store redeem data
  storeRedeemData(item) {
    this.redeemData = item;
  }

  async updatePermissionsVolunteer(uid, info) {
    const res = await fetch('https://europe-west2-yusra-app.cloudfunctions.net/updatePermissionsVolunteer', {
        method: 'POST',
        body: JSON.stringify({ uid, info })
    });
    const data = await res.json();
    data.body = JSON.parse(data.body);
    return data.body;
  }

  // volunteer accept reject
  getMyFullName(uid) {
    return new Promise((resolve, reject) => {
      const personalDetailsFullNameDoc = this.db.doc(`users/${uid}/personalDetails/fullName`);
      const personalDetailsFullNameObs = personalDetailsFullNameDoc.valueChanges();
      personalDetailsFullNameObs.pipe(take(1)).subscribe((data: { firstName: string, lastName: string }) => {
        resolve({ firstName: data.firstName, lastName: data.lastName });
      })
    });
  }

  confirmVolunteer(uid, info) {
    return new Promise(async(resolve, reject) => {
      // add 'volunteer' document with 'val: true' to users permissions collection
      // This needs to be done with admin permissions so do it via cloud functions
      const updatePermissionsResp = await this.updatePermissionsVolunteer(uid, info);
      if(updatePermissionsResp.status) {
        // If above succeeds send notification to user who has just become a volunteer
        const notificationObj = {
          date: (new Date()).toString(),
          icon: 'happy',
          id: '',
          message: 'Congratulations! Your request to become a volunteer has just been accepted by ' + info.firstName + ' ' + info.lastName + '. You can now start searching for tasks.',
          pid: '',
          time: new Date().getTime(),
          title: 'Volunteer Request Accepted!',
          uid: '',
          url: 'home/tabs/tab2/search'
        }
        this.addUniqueDoc('users', 'notifications', uid, notificationObj).then((res: { status: boolean, data: any }) => {
          if(res.status) {
            // Resolve true
            resolve(true);
          } else {
            this.fireSwal('Error', res.data.message, 'error');
            resolve(false);
          }
        })
      } else {
        // Else resolve error message
        this.fireSwal('Error', updatePermissionsResp.error, 'error');
        resolve(false);
      }
    });
  }

  rejectVolunteer(uid, notificationObj) {
    return new Promise((resolve, reject) => {
      // send a notification to the user who attempted to become a volunteer
      this.addUniqueDoc('users', 'notifications', uid, notificationObj).then((res: { status: boolean, data: any }) => {
        if(res.status) {
          resolve(true);
        } else {
          this.fireSwal('Error', res.data.message, 'error');
          resolve(false);
        }
      })
    });
  }

  // Notifications

  sendNotification(uid, notificationObj) {
    return new Promise((resolve, reject) => {
      this.db.collection(`users/${uid}/notifications`).add(notificationObj).then((ref) => {
        this.db.doc(`users/${uid}/notifications/${ref.id}`).update({ id: ref.id }).then(() => {
          resolve(true);
        }).catch((error) => {
          reject(error);
        });
      }).catch((error) => {
        reject(error);
      })
    });
  }

  // accept, reject, complete, cancel, delete post

  acceptTask(userId, postId, newList, uid, postTitle) {
    return new Promise(async(resolve, reject) => {
      newList.push(userId);
      const notificationObj = {
        date: (new Date()).toString(),
        icon: 'hand',
        id: '',
        message: 'Please swipe left and either click on the tick to accept or the cross to reject this volunteers request.',
        pid: postId, // used to give a link to the post
        time: new Date().getTime(),
        title: 'New volunteer for task: ' + postTitle,
        uid: userId, // used to give a link to users profile
        url: ''
      }
      // Needs to be done via cloud fundtions due to permssions
      // Will also send the notification from there
      const res = await fetch('https://europe-west2-yusra-app.cloudfunctions.net/acceptTask', {
          method: 'POST',
          body: JSON.stringify({
              postId,
              newList,
              notificationObj,
              uid
          }),
      });
      const data = await res.json();
      data.body = JSON.parse(data.body);
      if(data.body.error) {
        reject(data.body.error);
      } else {
        resolve(data.body);
      }
    });
  }

  cancelTask(userId, postId, newList, uid, postTitle) {
    return new Promise(async(resolve, reject) => {
      newList.push(userId);
      const finalList = newList.filter(item => item !== userId);
      const notificationObj = {
        date: (new Date()).toString(),
        icon: 'sad',
        id: '',
        message: 'Unfortunately a volunteer is no longer able to complete your task. This volunteer has been removed from your list of accepted volunteers for this task.',
        pid: postId, // used to give a link to the post
        time: new Date().getTime(),
        title: 'Task cancelled: ' + postTitle,
        uid: userId, // used to give a link to users profile
        url: ''
      }
      // Needs to be done via cloud fundtions due to permssions
      // Will also send the notification from there
      const res = await fetch('https://europe-west2-yusra-app.cloudfunctions.net/cancelTask', {
          method: 'POST',
          body: JSON.stringify({
              postId,
              finalList,
              notificationObj,
              uid
          }),
      });
      const data = await res.json();
      data.body = JSON.parse(data.body);
      if(data.body.error) {
        reject(data.body.error);
      } else {
        resolve(data.body);
      }
    });
  }

  acceptVolunteerOntoTask(uid, pid) {
    return new Promise((resolve, reject) => {
      // get post data from posts/pid
      const postDoc = this.db.doc(`posts/${pid}`);
      const postObs = postDoc.valueChanges();
      // Get acceptedIdLIst from messages doc
      const messagesDoc = this.db.doc(`posts/${pid}/messages/messages`);
      const messagesObs = messagesDoc.valueChanges();
      postObs.pipe(take(1)).subscribe((postData: any) => {
        // remove uid from awaitingIdList
        const awaitingIdList = postData.awaitingIdList.filter(item => item !== uid);
        // add uid onto acceptedIdList
        postData.acceptedIdList.push(uid);
        // save above to database
        this.db.doc(`posts/${pid}`).update({ awaitingIdList: awaitingIdList, acceptedIdList: postData.acceptedIdList }).then(() => {
          messagesObs.pipe(take(1)).subscribe((messagesData: any) => {
            // add uid onto acceptedIdList
            messagesData.acceptedVolunteers.push(uid);
            // save above to database
            this.db.doc(`posts/${pid}/messages/messages`).update({ acceptedVolunteers: messagesData.acceptedVolunteers }).then(() => {
              // Send notification to volunteer
              const notificationObj = {
                date: (new Date()).toString(),
                icon: 'happy',
                id: '',
                message: 'Click the button below to go to the task and message the user. Please also ensure you read the safeguarding documents using the link below.',
                pid: pid,
                time: new Date().getTime(),
                title: 'You have just been accepted to complete a task!',
                uid: '',
                url: ''
              };
              this.sendNotification(uid, notificationObj).then(() => {
                resolve(true);
              }).catch((error) => {
                reject(error);
              });
            }).catch((error) => {
              reject(error);
            });
          });
        }).catch((error) => {
          reject(error);
        });
      });
    });
  }

  rejectVolunteerOntoTask(uid, pid) {
    return new Promise((resolve, reject) => {
      // get post data from posts/pid
      const postDoc = this.db.doc(`posts/${pid}`);
      const postObs = postDoc.valueChanges();
      // Get acceptedIdLIst from messages doc
      const messagesDoc = this.db.doc(`posts/${pid}/messages/messages`);
      const messagesObs = messagesDoc.valueChanges();
      postObs.pipe(take(1)).subscribe((postData: any) => {
        // remove uid from awaitingIdList
        const awaitingIdList = postData.awaitingIdList.filter(item => item !== uid);
        // save above to database
        this.db.doc(`posts/${pid}`).update({ awaitingIdList: awaitingIdList }).then(() => {
          messagesObs.pipe(take(1)).subscribe((messagesData: any) => {
            // remove uid from List
            const acceptedVolunteersFinal = messagesData.acceptedVolunteers.filter(item => item !== uid);
            // save above to database
            this.db.doc(`posts/${pid}/messages/messages`).update({ acceptedVolunteers: acceptedVolunteersFinal }).then(() => {
              // Send notification to volunteer
              const notificationObj = {
                date: (new Date()).toString(),
                icon: 'sad',
                id: '',
                message: 'Not to worry, if your intentions were correct you will still get rewarded! Click the button below to view the task.',
                pid: pid,
                time: new Date().getTime(),
                title: 'Unfortunately you have not been chosen to complete this task!',
                uid: '',
                url: ''
              };
              this.sendNotification(uid, notificationObj).then(() => {
                resolve(true);
              }).catch((error) => {
                reject(error);
              });
            }).catch((error) => {
              reject(error);
            });
          });
        }).catch((error) => {
          reject(error);
        });
      });
    });
  }

  deletePost(pid, coins, uid, country) {
    return new Promise((resolve, reject) => {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to delete this post!',
        showCancelButton: true,
        confirmButtonColor: '#c71414',
        cancelButtonColor: '#303034',
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          // this doesnt actually delete the post but just makes it inactive
          this.db.doc(`posts/${pid}`).update({ active: false, status: 'deleted' }).then(() => {
            this.deactivateMapSearch(false, pid, country).then(() => {
              if(coins > 0) {
                // If the coins for this task were > 0, remove coins from post
                // send coins back to user.
                this.reassignCoinsFromDeletedPost(pid, coins, uid).then(() => {
                  resolve(true);
                }).catch((error) => {
                  reject(error);
                });
              } else {
                resolve(true);
              }
            }).catch((error) => {
              reject(error);
            });
          }).catch((error) => {
            reject(error);
          });
        } else {
          reject(false);
        }
      });
    });
  }

  reassignCoinsFromDeletedPost(pid, coins, uid) {
    return new Promise(async(resolve, reject) => {
      let res = await this.awaitReassignCoinsFromDeletedPost(pid, coins, uid);
      if(res.body.error) {
        reject(res.body.error);
      } else {
        resolve(true);
      }
    });
  }

  async awaitReassignCoinsFromDeletedPost(pid, coins, uid) {
    const res = await fetch('https://europe-west2-yusra-app.cloudfunctions.net/postDeletedReassignCoins', {
        method: 'POST',
        body: JSON.stringify({
          pid,
          coins,
          uid
        }),
    });
    const data = await res.json();
    data.body = JSON.parse(data.body);
    return data;
  }

  // Get Sponsor

  getSponsor() {
    return new Promise((resolve, reject) => {
      const sponsorCol = this.db.collection('sponsor', ref => ref.where('active', '==', true));
      const sponsorObs = sponsorCol.valueChanges();
      resolve(sponsorObs);
    });
  }

  // get user reviews

  getUserReviews(userId) {
    return new Promise((resolve, reject) => {
      const reviewsCol = this.db.collection(`users/${userId}/reviews`);
      const reviewsObs = reviewsCol.valueChanges();
      resolve(reviewsObs);
    });
  }

  // add task

  addTask(mainTaskObj, contactInfoObj, coinsObj, messagesObj) {
    return new Promise((resolve, reject) => {
      // Add main doc
      this.db.collection('posts').add(mainTaskObj).then((ref) => {
        // update main doc id
        this.db.doc(`posts/${ref.id}`).update({ id: ref.id }).then(() => {
          mainTaskObj.id = ref.id;
          // Add contact Info sub doc
          this.db.doc(`posts/${ref.id}/contactInfo/contactInfo`).set(contactInfoObj, { merge: true }).then(() => {
            // Add coins sub doc
            this.db.doc(`posts/${ref.id}/coins/coins`).set(coinsObj, { merge: true }).then(() => {
              // Add empty message sub doc
              this.db.doc(`posts/${ref.id}/messages/messages`).set(messagesObj, { merge: true }).then(() => {
                // Update mapSearch doc
                this.createMapSearch(true, mainTaskObj.uid, ref.id, contactInfoObj.postCode, contactInfoObj.country, mainTaskObj.latitude, mainTaskObj.longitude, mainTaskObj.thumbnail, mainTaskObj.title, mainTaskObj.genderPref, mainTaskObj.coins, mainTaskObj.category).then(() => {
                  // Need to get current users email
                  const email = { email: this.getUserAuth().email };
                  // Need to send a message to admin
                  const adminObj = { ...mainTaskObj, ...contactInfoObj, ...email };
                  this.sendAdminNotification(adminObj, 'New Post').then(() => {
                    resolve(true);
                  }).catch((error) => {
                    reject(error);
                  });
                }).catch((error) => {
                  reject(error);
                });
              }).catch((error) => {
                reject(error);
              });
            }).catch((error) => {
              reject(error);
            });
          }).catch((error) => {
            reject(error);
          });
        }).catch((error) => {
          reject(error);
        });
      }).catch((error) => {
        reject(error);
      });
    });
  }

  // Admin Notifications

  sendAdminNotification(adminObj, notificationType) {
    return new Promise(async(resolve, reject) => {
      let res = await this.awaitAdminNotification(adminObj, notificationType);
      if(res.body.error) {
        reject(res.body.error);
      } else {
        resolve(true);
      }
    });
  }

  async awaitAdminNotification(obj, notificationType) {
    const res = await fetch('https://europe-west2-yusra-app.cloudfunctions.net/adminNotification', {
        method: 'POST',
        body: JSON.stringify({
          obj,
          notificationType
        }),
    });
    const data = await res.json();
    data.body = JSON.parse(data.body);
    return data;
  }

  // Map Search

  createMapSearch(active, uid, pid, postCode, country, lat, lon, staticMapImageLink, postTitle, genderPref, coins, category) {
    return new Promise(async(resolve, reject) => {
      let res = await this.awaitMapSearch(active, uid, pid, postCode, country, lat, lon, staticMapImageLink, 'create', postTitle, genderPref, coins, category);
      if(res.body.error) {
        reject(res.body.error);
      } else {
        // Data has been added to firestore now create a new feature in the correct dataset within mapbox
        // This will have active: false. When admin approves it then it can be made active: true.
        // mapSearch in firestore will also need to be updated by Admin.
        this.addFeatureToDataset(false, uid, pid, postCode, country, lat, lon, staticMapImageLink, postTitle, genderPref, coins, category, '').then(() => {
          resolve(true);
        }).catch((error) => {
          reject(error);
        });
      }
    });
  }

  readMapSearch(country) {
    return new Promise(async(resolve, reject) => {
      let res = await this.awaitMapSearch(true, '', '', '', country, 0, 0, '', 'read', '', '', 0, '');
      if(res.body.error) {
        reject(res.body.error);
      } else {
        resolve(res.body.data);
      }
    });
  }

  updateMapSearch(active, uid, pid, postCode, country, lat, lon, staticMapImageLink, postTitle, genderPref, coins, category) {
    return new Promise(async(resolve, reject) => {
      let res = await this.awaitMapSearch(active, uid, pid, postCode, country, lat, lon, staticMapImageLink, 'update', postTitle, genderPref, coins, category);
      if(res.body.error) {
        reject(res.body.error);
      } else {
        // Data has been added to firestore now update the feature in the correct dataset within mapbox
        this.addFeatureToDataset(true, uid, pid, postCode, country, lat, lon, staticMapImageLink, postTitle, genderPref, coins, category, '').then(() => {
          resolve(true);
        }).catch((error) => {
          reject(error);
        });
      }
    });
  }

  deactivateMapSearch(active, pid, country) {
    return new Promise(async(resolve, reject) => {
      let res = await this.awaitMapSearch(active, '', pid, '', country, 0, 0, '', 'deactivate', '', '', 0, '');
      if(res.body.error) {
        reject(res.body.error);
      } else {
        // Data has been updated in firestore. This feature will now be deleted from dataset.
        // If user wants to reactivate this post the feature will need to be added again using addFeatureToDataset()
        this.removeFeatureFromDataset(country, pid).then(() => {
          resolve(true);
        }).catch((error) => {
          reject(error);
        });
      }
    });
  }

  async awaitMapSearch(active, uid, pid, postCode, country, lat, lon, staticMapImageLink, command, postTitle, genderPref, coins, category) {
    const res = await fetch('https://europe-west2-yusra-app.cloudfunctions.net/mapSearch', {
        method: 'POST',
        body: JSON.stringify({
          active,
          uid,
          pid,
          postCode,
          country,
          lat,
          lon,
          staticMapImageLink,
          command,
          postTitle,
          genderPref,
          coins,
          category
        }),
    });
    const data = await res.json();
    data.body = JSON.parse(data.body);
    return data;
  }

  addFeatureToDataset(active, uid, pid, postCode, country, lat, lon, staticMapImageLink, postTitle, genderPref, coins, category, offerText) {
    return new Promise((resolve, reject) => {
      const finalCategory = category.replace(/\s/g, '');
      let dataSet = 'UK';
      if(country !== 'United Kingdom') {
        dataSet = country;
      }
      const body = {
        id: pid,
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: [lon, lat]
        },
        properties: {
          active: active,
          lat: lat,
          lon: lon,
          postCode: postCode,
          pid: pid,
          uid: uid,
          staticMapImageLink: staticMapImageLink,
          postTitle: postTitle,
          category: finalCategory,
          coins: coins,
          genderPref: genderPref,
          offerText: offerText
        }
      }
      this.getAddress.updateMapboxFeature(dataSet, body).then((resp: Observable<any>) => {
        resp.pipe((take(1))).subscribe((respData: any) => {
          resolve(true);
        });
      }).catch((error) => {
        reject(error);
      })
    });
    
  }

  removeFeatureFromDataset(country, pid) {
    return new Promise((resolve, reject) => {
      let dataSet = 'UK';
      if(country !== 'United Kingdom') {
        dataSet = country;
      }
      this.getAddress.deleteMapboxFeature(dataSet, pid).then((resp: Observable<any>) => {
        resp.pipe((take(1))).subscribe((respData: any) => {
          resolve(true);
        });
      }).catch((error) => {
        reject(error);
      })
    });
  }

  // Messages

  returnMessagesData(pid) {
    return new Promise((resolve, reject) => {
      const newDoc = this.db.doc(`posts/${pid}/messages/messages`).valueChanges();
      resolve(newDoc);
    });
  }

  returnMessagesDoc(pid) {
    const newDoc = this.db.doc(`posts/${pid}/messages/messages`);
    const newObs = newDoc.valueChanges();
    return newObs;
  }

  returnManageDataDoc(pid, listOfAllUsers) {
    return new Promise((resolve, reject) => {
      this.getIndivMessageManageUser(listOfAllUsers[0].uid, 'owner').then((ownerData) => {
        const finalArray = [];
        finalArray.push(ownerData);
        for(let i of listOfAllUsers[1].uid) { // participants
          this.getIndivMessageManageUser(i, 'participant').then((participantData) => {
            finalArray.push(participantData);
          }).catch((error) => {
            this.fireSwal('Participant Error', error.message, 'error');
          });
        }
        for(let i of listOfAllUsers[2].uid) { // participants
          this.getIndivMessageManageUser(i, 'volunteer').then((volunteerData) => {
            finalArray.push(volunteerData);
          }).catch((error) => {
            this.fireSwal('Participant Error', error.message, 'error');
          });
        }
        // convert array to observable
        const finalObs = of(finalArray);
        resolve(finalObs);
      }).catch((error) => {
        this.fireSwal('Owner Error', error.message, 'error');
      })
    });
  }

  getIndivMessageManageUser(uid, chatStatus) {
    return new Promise((resolve, reject) => {
      const userDoc = this.db.doc<UserInterface>(`users/${uid}`);
      const userObs = userDoc.valueChanges();
      userObs.pipe(take(1)).subscribe(async(userData) => {
        await this.getFullNameAdmin(uid).then((fullNameData) => {
          if(!fullNameData['error']) {
            const queryObj = {
              firstName: fullNameData['firstName'],
              lastName: fullNameData['lastName'],
              chatStatus: chatStatus,
              gender: userData.gender,
              languages: userData.languages,
              userType: userData.userType,
              employed: userData.employed,
              industry: userData.industry,
              uid: userData.uid,
              anonymous: userData.anonymous
            };
            resolve(queryObj);
          } else {
            reject({ message: 'Unable to get name of user!' });
          }
        });
      });
    });
  }

  returnFindDataDoc(lat, lng, country) {
    return new Promise((resolve, reject) => {
      let usersFound = this.db.collection('users', ref => ref.where('active', '==', true).where('step', '==', 5).where('country', '==', country).where('anonymous', '==', false));
      usersFound.get().pipe(take(1)).subscribe((querySnapshot) => {
        this.getNearbyUsers(querySnapshot, 10, lat, lng).then((nearbyUsersObs) => {
          resolve(nearbyUsersObs);
        }).catch((error) => {
          this.fireSwal('Error', error.message, 'error');
        });
      });
    });
  }

  getNearbyUsers(usersByCountry, radius, lat, lng) {
    return new Promise((resolve, reject) => {
      this.getUsersByRadius(usersByCountry, lat, lng, radius).then((listOfUsersInRadius) => {
        this.nearbyUsersObs = of(listOfUsersInRadius);
        resolve(this.nearbyUsersObs);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  getUsersByRadius(newUserData, lat, lng, miles) {
    return new Promise((resolve, reject) => {
      let finalList = [];
      const km = this.getKiloMetersFromMiles(miles);
      newUserData.forEach((item) => {
        const distance = this.arePointsNear({ lng: item.data().lon, lat: item.data().lat }, { lng: lng, lat: lat }, km);
        if(distance) {
          const finalMiles = this.getMilesFromKiloMeters(distance);
          const finalData = { ...{ distance: finalMiles }, ...item.data() };
          finalList.push(finalData);
        }
      });
      if(finalList.length === 0) {
        reject({ message: 'No users found!' });
      } else {
        resolve(finalList);
      }
    });
  }

  updateMessage(pid, data) {
    return new Promise((resolve, reject) => {
      this.db.doc(`posts/${pid}/messages/messages`).update({ messages: data }).then(() => {
        resolve(true);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  updateMessageNew(pid, data) {
    return new Promise((resolve, reject) => {
      this.db.doc(`posts/${pid}/messages/messages`).update(data).then(() => {
        resolve(true);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  updatePostDoc(id, obj) {
    return new Promise((resolve, reject) => {
      this.db.doc(`posts/${id}`).update(obj).then(() => {
        resolve(true);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  // Org and Admin Functions

  getOrgCoinsData(orgId, uid) {
    return new Promise((resolve, reject) => {
      // check if uid has org permissions
      let userPermissionsDoc = this.db.doc(`users/${uid}/permissions/org`);
      let userPermissionsObs = userPermissionsDoc.valueChanges();
      userPermissionsObs.pipe(take(1)).subscribe((userPermissionsData: any) => {
        if(userPermissionsData.val) {
          // If yes then get coins using orgId
          let orgCoinsDoc = this.db.doc(`orgs/${orgId}/coins/coins`);
          let orgCoinsObs = orgCoinsDoc.valueChanges();
          resolve(orgCoinsObs);
        } else {
          reject({ message: 'You do not have permission to access organisational level data!' })
        }
      });
    });
  }

  checkUserPermissions(permission, cap) {
    return new Promise((resolve, reject) => {
      if(this['user'+cap] === undefined) {
        this.au.auth.onAuthStateChanged((user) => {
          if (user) {
            let userPermissionsDoc = this.db.doc(`users/${user.uid}/permissions/${permission}`);
            let userPermissionsObs = userPermissionsDoc.valueChanges();
            userPermissionsObs.pipe(take(1)).subscribe((userPermissionData: any) => {
              if(userPermissionData.val) {
                this['user'+cap] = true;
                resolve(true);
              } else {
                this['user'+cap] = false;
                resolve(false);
              }
            });
          } else {
            this.fireSwal('Error', 'User not logged in!', 'error');
          }
        });
      } else {
        resolve(this['user'+cap]);
      }
    });
  }

  // Org tasks

  rejectOrder(uid, notificationObj, type, productId, orderId, orgId) {
    return new Promise((resolve, reject) => {
      // Get users current redeemed list
      const redeemedObs = this.db.doc(`users/${uid}/${type}/redeemed`).valueChanges();
      redeemedObs.pipe((take(1))).subscribe((redeemedData: any) => {
        const redeemedList = redeemedData.redeemedList;
        // Loop through list and look for id 'productId'. Change status of that item to 'rejected'
        for(let i of redeemedList) {
          if(i.id === productId) {
            i.status = 'rejected'
            break;
          }
        }
        // Update users redeemedList
        this.db.doc(`users/${uid}/${type}/redeemed`).update({ redeemedList: redeemedList }).then(() => {
          this.sendNotification(uid, notificationObj).then(() => {
            // Update the order status to 'rejected'
            this.db.doc(`orgs/${orgId}/orders/${orderId}`).update({ status: 'rejected' }).then(() => {
              resolve(true);
            }).catch((error) => {
              reject(error);
            });
          }).catch((error) => {
            reject(error);
          })
        }).catch((error) => {
          reject(error);
        });
      });
    });
  }

  acceptOrder(uid, notificationObj, type, productId, orderId, orgId, coins) {
    return new Promise((resolve, reject) => {
      if(type[0] === 'rewards') {
        // Get users current coins
        const coinsObs = this.db.doc(`users/${uid}/coins/coins`).valueChanges();
        coinsObs.pipe(take(1)).subscribe(async(coinsData: any) => {
          const totalCoins = coinsData.total;
          if(totalCoins >= coins) {
            // This user has enough coins. Deduct user coins. Add those coins to org
            let res = await this.awaitDeductUserCoinsAddToOrg(uid, productId, orderId, orgId, coins);
            if(res.body.error) {
              reject(res.body.error);
            } else {
              this.continueAcceptOrder(uid, notificationObj, type[0], productId, orderId, orgId).then(() => {
                resolve(true);
              }).catch((error) => {
                reject(error);
              });
            }
          } else {
            // This user doesn't have enough coins so don't continue
            reject({ message: 'This user no longer has enough coins to redeem this item!' });
          }
        });
      } else {
        this.continueAcceptOrder(uid, notificationObj, type[0], productId, orderId, orgId).then(() => {
          resolve(true);
        }).catch((error) => {
          reject(error);
        });
      }
    });
  }

  continueAcceptOrder(uid, notificationObj, type, productId, orderId, orgId) {
    return new Promise((resolve, reject) => {
      // Get users current redeemed list
      const redeemedObs = this.db.doc(`users/${uid}/${type}/redeemed`).valueChanges();
      const typeObs = this.db.doc(`users/${uid}/${type}/${type}`).valueChanges();
      redeemedObs.pipe((take(1))).subscribe((redeemedData: any) => {
        const redeemedList = redeemedData.redeemedList;
        // Loop through list and look for id 'productId'. Change status of that item to 'accepted'
        for(let i of redeemedList) {
          if(i.id === productId) {
            i.status = 'accepted'
            break;
          }
        }
        // Update users redeemedList
        this.db.doc(`users/${uid}/${type}/redeemed`).update({ redeemedList: redeemedList }).then(() => {
          typeObs.pipe((take(1))).subscribe((typeData: any) => {
            const rewardsList = typeData.rewardsList;
            // Loop through list and remove item productId
            for(let i = 0; i < rewardsList.length; i++) {
              if(rewardsList[i] === productId) {
                rewardsList.splice(i, 1);
                break;
              }
            }
            this.sendNotification(uid, notificationObj).then(() => {
              // Update the order status to 'rejected'
              this.db.doc(`orgs/${orgId}/orders/${orderId}`).update({ status: 'accepted' }).then(() => {
                resolve(true);
              }).catch((error) => {
                reject(error);
              });
            }).catch((error) => {
              reject(error);
            });
          });
        }).catch((error) => {
          reject(error);
        });
      });
    });
  }

  async awaitDeductUserCoinsAddToOrg(uid, productId, orderId, orgId, coins) {
    const res = await fetch('https://europe-west2-yusra-app.cloudfunctions.net/deductUserCoinsAddToOrgNew', {
        method: 'POST',
        body: JSON.stringify({
          uid, productId, orderId, orgId, coins
        }),
    });
    const data = await res.json();
    data.body = JSON.parse(data.body);
    return data;
  }

  sendEmailToDbs(data) {
    return new Promise(async(resolve, reject) => {
      let res = await this.awaitSendEmailToDbs(data);
      if(res.body.error) {
        reject(res.body.error);
      } else {
        resolve(true);
      }
    });
  }

  async awaitSendEmailToDbs(data) {
    const res = await fetch('https://europe-west2-yusra-app.cloudfunctions.net/sendEmailToDbs', {
        method: 'POST',
        body: JSON.stringify({
          data
        }),
    });
    const data2 = await res.json();
    data2.body = JSON.parse(data2.body);
    return data2;
  }

  sendInviteEmail(uid, pid, title, message) {
    return new Promise(async(resolve, reject) => {
      let res = await this.awaitSendEmail(uid, 'joinGroup', pid, title, message);
      if(res.body.error) {
        reject(res.body.error);
      } else {
        resolve(true);
      }
    });
  }

  async awaitSendEmail(email, template, pid, title, message) { // email is actually UID
    const res = await fetch('https://europe-west2-yusra-app.cloudfunctions.net/sendMailNew', {
      method: 'POST',
      body: JSON.stringify({ email, template, pid, title, message })
    });
    const data = await res.json();
    data.body = JSON.parse(data.body);
    return data;
  }

}
