import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class TutorialGuard implements CanActivate {

  constructor(private router: Router, private au: AngularFireAuth) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {

    const isComplete = await Storage.get({ key: 'tutorialComplete' });
    let loggedIn = false;
    await this.au.user.pipe(take(1)).subscribe((user:any) => { 
      if(user != null) {
        loggedIn = true;
      }
    });

    if (isComplete.value != 'true') {
      this.router.navigateByUrl('/tutorial');
    } else {
      if(loggedIn) { //also managed redirect of logged in users to home
        this.router.navigateByUrl('/home');
      } else {
        return true; //allow user to go to login screen as they are not logged in
      }
    }
  }
}


