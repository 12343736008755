import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// import { CometChat } from '@cometchat-pro/chat';

import { defineCustomElements } from '@ionic/pwa-elements/loader';

if (environment.production) {
  enableProdMode();
}

/*const appID = environment.cometChat.appId;
const region = environment.cometChat.region;
const appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(region).build();
CometChat.init(appID, appSetting).then(() => {
  console.log("Initialization completed successfully");
}, (error) => {
  console.log("Initialization failed with error:", error);
  // Check the reason for error and take appropriate action.
});*/

platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
  const isSafari = () => {
    return navigator.userAgent.indexOf('Safari') !== -1;
  };
  if (isSafari()) {
    console.log('Service Worker not registered');
  } else {
    if ('serviceWorker' in navigator && environment.production) {
      navigator.serviceWorker.register('/ngsw-worker.js');
      console.log('Service Worker registered');
    }
  }
}).catch(error => console.log(error));

  // Call the element loader after the platform has been bootstrapped
defineCustomElements(window);