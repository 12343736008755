// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyAYfAr_AM2sT3n-nCgjtFQwZkAiv6pcRio",
    authDomain: "yusra-app.firebaseapp.com",
    databaseURL: "https://yusra-app.firebaseio.com",
    projectId: "yusra-app",
    storageBucket: "yusra-app.appspot.com",
    messagingSenderId: "824096899920",
    appId: "1:824096899920:web:a550c35573d61a84e12255",
    measurementId: "G-KBX76ELQSM"
  },
  googleMapsAPIKey: "AIzaSyA-UwxQUvU8PiX_V8MCIQTgS3EwBInV5V0",
  getAddressAPI: "t17vvH2OZEemuOeHIZuH1Q22005",
  proMapTools: "17o8dysaCDrgv1c",
  mapQuestApi: "tpYQeslnZOgejWBhfGK1sTsoG0bK8rZO",
  stripeKeyTest: "pk_live_SJ5H90UetbrVwfACffUrxR0o00yJ4ROrUN", // live
  stripeKeyTestSecret: "sk_live_ATDRiewb3VJ9T1dFVA9ER9O300LOnG2zdv",
  stripeKey: "pk_test_tSFR8857LE7LBWk1SeBOBhf100cb1zlepY", //test
  stripeKeySecret: "sk_test_dVqunOPcFH6pBAplswXzuChf00WdGsHDeI",
  UPS: "DD789B959862ADB2",
  mapbox: {
    sk: "sk.eyJ1IjoibXVqYWhpZDUxNSIsImEiOiJjazc4djd3ZzcwajQ0M2ZueWRnN3Vjb2lpIn0.Q-ADsDYEqc2yGd-zFXW45w",
    pk: "pk.eyJ1IjoibXVqYWhpZDUxNSIsImEiOiJjazc4dXFsY3QwamQwM2xwZGhweW5seDg1In0.IHyRB3cpuJL6SyFeCQFaEA",
    username: "mujahid515",
    datasets: {
      UK: "ck7a9jgio0i1d2oqvatakfbu2"
    }
  },
  cometChat: {
    api: "43bc2d345b1fbbb4e14fc47a87991c187afa0153",
    region: "eu",
    appId: "17156a106feb7f5"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
