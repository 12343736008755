import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GetAddressService {

  constructor(private http: HttpClient) { }

  getAddress(postCode) {
    return this.http.get<any>(`https://api.getAddress.io/find/${postCode}?api-key=${environment.getAddressAPI}&expand=true`);
  }

  getLatLon(postCode, country) { // consider using maqquestapi for this? <<<<<<<<<<<<<<<<<<<<<<<<<<<<
    if(country === 'United Kingdom') {
      return this.http.get<any>(`https://api.getthedata.com/postcode/${postCode}`);
      // return this.http.get<any>(`https://api.promaptools.com/service/uk/postcode-lat-lng/get/?postcode=${postCode}&key=${environment.proMapTools}`);
      // return this.http.get<any>(`http://open.mapquestapi.com/geocoding/v1/address?key=${environment.mapQuestApi}&location=${postCode}`)
      // Map quest wasnt good for this. They returned results even for fake addresses
    } else if(country === 'United States') {
      // <<<<<<<<<<<<<<<<<< Need to find a service for USA
      // Use UPS API KEY ${environment.UPS}
      // MIght end up using promaptools as they seem quite good.
      return false;
    } else {
      return false;
    }
  }

  getStaticMapImage(lat, lon) {
    // return `https://open.mapquestapi.com/staticmap/v4/getmap?key=${environment.mapQuestApi}&size=600,600&zoom=16&center=${lat},${lon}&pois=x,${lat},${lon}&imagetype=png`;
    return `https://api.mapbox.com/styles/v1/mapbox/streets-v9/static/pin-l-star+217673(${lon},${lat})/${lon},${lat},16,0/600x600?access_token=${environment.mapbox.pk}&logo=false`;
  }

  getListOfActivePostsByRadius(lat, lon, radius, country) {
    if(country === 'United Kingdom') {
      country = 'UK';
    }
    return this.http.get<any>(`https://www.mapquestapi.com/search/v2/radius?key=${environment.mapQuestApi}&radius=${radius}&origin=${lat},${lon}&hostedData=mqap.${environment.mapQuestApi}_yusraAppPosts${country}|active=?|true`);
  }

  getMapboxListFeatures(country) {
    if(country === 'United Kingdom') {
      country = 'UK';
    }
    const username = environment.mapbox.username;
    const dataSetId = environment.mapbox.datasets[country];
    if(dataSetId == undefined) {
      return false;
    } else {
      return this.http.get<any>(`https://api.mapbox.com/datasets/v1/${username}/${dataSetId}/features?access_token=${environment.mapbox.pk}`);
    }
  }

  updateMapboxFeature(dataSet, body) {
    return new Promise((resolve, reject) => {
      const username = environment.mapbox.username;
      const dataSetId = environment.mapbox.datasets[dataSet];
      if(dataSetId == undefined) {
        reject({ error: { message: 'Dataset does not exist in ' + dataSet }});
      } else {
        const finalBody = JSON.stringify(body);
        const resp = this.http.put<any>(`https://api.mapbox.com/datasets/v1/${username}/${dataSetId}/features/${body.id}?access_token=${environment.mapbox.sk}`, finalBody, {headers: {'Content-Type':'application/json'}});
        resolve(resp);
      }
    });
  }

  deleteMapboxFeature(dataSet, pid) {
    return new Promise((resolve, reject) => {
      const username = environment.mapbox.username;
      const dataSetId = environment.mapbox.datasets[dataSet];
      if(dataSetId == undefined) {
        reject({ error: { message: 'Dataset does not exist in ' + dataSet }});
      } else {
        const resp = this.http.delete<any>(`https://api.mapbox.com/datasets/v1/${username}/${dataSetId}/features/${pid}?access_token=${environment.mapbox.sk}`);
        resolve(resp);
      }
    });
  }
}
